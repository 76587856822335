<template>
  <vx-card>
    <div class="flex items-center justify-between">
      <div class="flex items-center">
        <img v-if="thumbnail" :src="thumbnail" width="125" class="rounded-lg hidden sm:block mr-5" draggable="false" />
        <div class="">
          <h1 class="font-bold term-name">{{ name }}</h1>
          <p class="font-light">{{ termsCount }} Total Terms</p>
          <vs-button
            color="dark"
            icon-pack="feather"
            icon="icon-play"
            class="mt-2 font-bold px-4 py-2 text-white"
            type="border"
            @click="$emit(`practice_set`, id, domain, name, thumbnail, percent, termsCompleted, termsCount)"
            >Practice
          </vs-button>
        </div>
      </div>
      <radial-progress-bar
        :diameter="115"
        :completed-steps="termsCompleted"
        :total-steps="termsCount"
        :animateSpeed="1100"
        timingFunc="ease"
        innerStrokeColor="#181d2a"
        startColor="#31B952"
        stopColor="#0cdc62"
        :strokeWidth="7"
        :innerStrokeWidth="7"
      >
        <h2 class="font-light">{{ percent }}%</h2>
      </radial-progress-bar>
    </div>
  </vx-card>
</template>

<script>
import RadialProgressBar from 'vue-radial-progress';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    domain: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    thumbnail: {
      type: String,
      default: null,
      required: false,
    },
    percent: {
      type: Number,
      default: 0,
      required: false,
    },
    termsCompleted: {
      type: Number,
      default: 0,
      required: false,
    },
    termsCount: {
      type: Number,
      default: 0,
      required: false,
    },
  },
  components: {
    RadialProgressBar,
  },
};
</script>

<style>
.term-name {
  font-size: 22px;
}

@media (max-width: 750px) {
  .term-name {
    font-size: 18px;
  }
}
</style>
