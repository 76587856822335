<template>
  <div>
    <div
      class="rounded-lg justify-between items-center rounded-lg mb-10"
      style="
        background-image: url(https://connect-cdn.intellectualpoint.com/assets/images/backgrounds/global-banner.png);
        box-shadow: rgb(0 0 0 / 10%) 0px 4px 25px 0px;
      "
    >
      <div class="flex justify-between items-center rounded-lg p-4" style="border: 1px solid #181d2a">
        <div class="flex items-center">
          <img
            alt="simulations"
            draggable="false"
            src="https://connect-cdn.intellectualpoint.com/assets/images/apps/FlashCardsIcon.png"
            class="rounded hidden sm:block mr-5"
            style="width: 75px"
          />
          <div>
            <h1 class="font-bold">Study Set Selector</h1>
            <h5 class="font-light">{{ totalSets }} Total Sets</h5>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-row" v-if="selectorData">
      <div class="vx-col w-full">
        <div class="set-container" v-for="(domain, dIndex) in selectorData" :key="dIndex">
          <div class="set-domain-container">
            <h2 class="font-medium p-3 px-8 rounded-lg set-domain-name" style="background-color: #040815; border: 1px solid #181d2a">
              {{ domain.name }}
            </h2>
            <h2 class="font-light p-3 px-8 rounded-lg set-domain-sets" style="background-color: #040815; border: 1px solid #181d2a">
              {{ domain.sets.length }} Sets
            </h2>
          </div>
          <div class="grid grid-cols-sets gap-5">
            <div v-for="(set, index) in domain.sets" :key="index" class="flex items-center justify-center">
              <study-set-card
                :id="set.id"
                :domain="domain.id"
                :name="set.name"
                :thumbnail="set.thumbnail"
                :percent="set.percent"
                :termsCompleted="set.termsCompleted"
                :termsCount="set.termsCount"
                v-on:practice_set="practiceSet"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="simple-spinner">
      <span></span>
    </div>

    <transition name="PracticeSetPopupOverlay">
      <div v-if="practicePopupActive" class="PracticeSetPopupOverlay" @click="practicePopupActive = false"></div>
    </transition>

    <transition name="PracticeSetPopupOverlay">
      <div v-if="practicePopupActive" class="PracticeSetPopup" ref="practiceMethodsContainer">
        <div class="PracticeMethod cursor-pointer" v-for="method in practiceMethods" :key="method.id">
          <div
            class="PracticeMethodCard"
            :class="method.disabled && 'disabled'"
            @click="
              $router.push(!method.disabled ? `/study/${method.type}/${practicePopupData.id}/play?domain=${practicePopupData.domain}` : '')
            "
            :style="{
              backgroundImage: method.disabled ? `url(${method.thumbnailDisabled})` : `url(${method.thumbnail})`,
            }"
          >
            <div class="SetDetailsContainer select-none">
              <div v-if="method.disabled && !method.unavailable" class="SetDetailsDisabled flex flex-col">
                <h1>Mode Unavailable</h1>
                <h4>Not Enough Terms</h4>
              </div>
              <div v-if="method.unavailable" class="SetDetailsDisabled flex flex-col">
                <h1>Mode Unavailable</h1>
                <h4>Coming Soon!</h4>
              </div>
              <div v-if="!method.disabled && !method.unavailable" class="SetDetails flex flex-col">
                <h1>{{ practicePopupData.name }}</h1>
                <h4>{{ practicePopupData.termsCount }} Total Terms</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import LottieAnimation from '../../../../components/lottie/LottieAnimation';
import StudySetCard from './components/StudySetCard';
import RadialProgressBar from 'vue-radial-progress';

export default {
  data() {
    return {
      courseId: null,
      selectorData: null,
      totalSets: 0,

      practicePopupActive: false,
      practicePopupData: {
        id: null,
        domain: null,
        name: null,
        thumbnail: null,
        percent: 0,
        termsCompleted: 0,
        termsCount: 0,
      },

      practiceMethods: [
        {
          id: 1,
          title: 'FlashCards',
          type: 'flashcards',
          thumbnail: 'https://connect-cdn.intellectualpoint.com/assets/images/apps/study-app/flashcards-tool-thumbnail.png',
          disabled: false,
          compliance: true,
        },
        {
          id: 2,
          title: 'Matching',
          type: 'match',
          thumbnail: 'https://connect-cdn.intellectualpoint.com/assets/images/apps/study-app/matching-tool-thumbnail.png',
          thumbnailDisabled: 'https://connect-cdn.intellectualpoint.com/assets/images/apps/study-app/matching-tool-thumbnail-disabled.png',
          disabled: false,
          compliance: false,
        },
        {
          id: 3,
          title: 'Quiz',
          type: 'quiz',
          thumbnail: 'https://connect-cdn.intellectualpoint.com/assets/images/apps/study-app/quiz-tool-thumbnail.png',
          thumbnailDisabled: 'https://connect-cdn.intellectualpoint.com/assets/images/apps/study-app/quiz-tool-thumbnail-disabled.png',
          disabled: true,
          unavailable: true,
        },
      ],

      showHiddenSets: false,
    };
  },
  computed: {
    setColorProgress() {
      const theme = this.$store.state.theme;
      if (theme === 'light') return 'rgba(0,0,0,0.2)';
      if (theme === 'dusk') return 'rgba(0,0,0,0.5)';
      if (theme === 'dark') return 'rgba(0,0,0,0.2)';
    },
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
    showIndex() {
      return this.practiceMethods.length;
    },
  },
  components: {
    StudySetCard,
    LottieAnimation,
    RadialProgressBar,
  },
  methods: {
    fetchSelectorData() {
      if (this.activeCourseInfo.selected === false) {
        this.$vs.notify({
          title: 'No Course Selected',
          text: 'Please select a course before practicing',
          color: 'danger',
          position: 'top-right',
        });

        this.$router.push('/courses');

        return;
      }

      this.$http
        .get(`study/set/selector?courseId=${this.courseId}`)
        .then((response) => {
          if (response.status === 200) {
            this.selectorData = response.data.selector;
            let length = 0;
            this.selectorData.forEach((domain) => {
              length = length + domain.sets.length;
            });
            this.totalSets = length;
          }
        })
        .catch(() => {});
    },
    lottiePlay(ref) {
      this.$refs[ref][0].play();
    },
    lottieStop(ref) {
      this.$refs[ref][0].stop();
    },
    practiceSet(id, domain, name, thumbnail, percent, termsCompleted, termsCount) {
      this.practicePopupData = {
        id,
        domain,
        name,
        thumbnail,
        percent,
        termsCompleted,
        termsCount,
      };

      if (termsCount < 6) {
        this.practiceMethods[1].disabled = true;
      } else {
        this.practiceMethods[1].disabled = false;
      }

      this.practicePopupActive = true;
      this.animateMethods();
    },
    animateMethods() {
      setTimeout(() => {
        const container = this.$refs.practiceMethodsContainer;
        const methods = container.querySelectorAll('.PracticeMethod');

        methods.forEach((method, index) => {
          setTimeout(() => {
            method.style.opacity = '1';
            method.style.transform = 'translateY(0)';
          }, index * 200);
        });
      }, 100);
    },
  },
  created() {
    this.courseId = this.activeCourseInfo.id;
    this.fetchSelectorData();

    setTimeout(() => {
      this.currentStep = 1;
    }, 1500);
  },
  beforeDestroy() {
    this.courseData = null;
    this.selectorData = null;
  },
};
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: url('~@/assets/images/cursor/cursor-pointer.png'), auto !important;
}
.grid-cols-sets {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.set-container {
  padding: 1.5rem;
  background-color: #02050d;
  border-radius: 0.6rem;
}

.set-domain-container {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin-bottom: 1rem;
}

.PracticeSetPopupOverlay-enter-active,
.PracticeSetPopupOverlay-leave-active {
  transition: opacity 0.5s;
}

.PracticeSetPopupOverlay-enter,
.PracticeSetPopupOverlay-leave-to {
  opacity: 0;
}

.PracticeSetPopupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  z-index: 99998;
}

.PracticeSetPopup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 1300px;
  height: 80%;
  max-height: 600px;
  border-radius: 2rem;
  z-index: 99999;
  display: flex;
  justify-content: space-between;
  column-gap: 4rem;
}

.PracticeMethod {
  flex: 1;
  height: 100%;
  border-radius: 2rem;
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.5s cubic-bezier(0.35, 0.4, 0.25, 1);
  background-size: cover;
}

.PracticeMethodCard {
  width: 100%;
  height: 100%;
  background-size: cover;
  transition: all 0.3s ease-in-out;
  border-radius: 2rem;
  border: 2px solid #0053ff;
  position: relative;
  background-position: center;

  &:hover {
    -webkit-box-shadow: 0px 0px 42px 0px rgba(0, 83, 255, 1);
    -moz-box-shadow: 0px 0px 42px 0px rgba(0, 83, 255, 1);
    box-shadow: 0px 0px 42px 0px rgba(0, 83, 255, 1);
  }

  &.disabled {
    border: 2px solid #bdbdbd;
    cursor: not-allowed;

    &:hover {
      -webkit-box-shadow: 0px 0px 32px 0px rgba(255, 255, 255, 0.4);
      -moz-box-shadow: 0px 0px 32px 0px rgba(255, 255, 255, 0.4);
      box-shadow: 0px 0px 32px 0px rgba(255, 255, 255, 0.4);
    }
  }
}

.SetDetailsContainer {
  width: 100%;
  position: absolute;
  bottom: 16%;
  text-align: center;
  justify-content: center;
  display: flex;
}

.SetDetails {
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 1.2rem;
  border-radius: 0.6rem;
  background-color: rgba(0, 83, 255, 0.3);
}

.SetDetailsDisabled {
  width: fit-content;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 1.2rem;
  border-radius: 0.6rem;
  background-color: rgba(255, 255, 255, 0.2);
}

@media (max-width: 1800px) {
  .grid-cols-sets {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 1350px) {
  .grid-cols-sets {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media (min-width: 1000px) and (max-width: 1150px) {
  .grid-cols-sets {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (max-width: 750px) {
  .set-domain-name {
    font-size: 18px;
    padding: 0rem !important;
    border: none !important;
  }

  .set-domain-sets {
    font-size: 18px;
    border: none !important;
    padding: 0rem !important;
  }

  .set-container {
    padding: 0rem;
    background-color: transparent;
    border-radius: 0.6rem;
  }

  .set-domain-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    column-gap: 1rem;
    border: 1px solid #181d2a;
    background-color: #02050d;
    padding: 0.8rem 1rem 0.8rem 1rem;
    border-radius: 0.6rem;
  }

  .set-domain-sets {
    margin-top: 0rem;
  }
}
</style>
